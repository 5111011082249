import './App.css';
import Navigation from './components/Navigation';
import Home from './components/Home';

function App() {
  return (
    <>
    {/* https://auth-staging.socialprintstudio.com/identify/https%3A%2F%2Fbuild.socialprintstudio.com%2FcreateBook%3Flinen%3Dred%26code%3Dphoto-books-premium-embossed-pixfizz%26bookID%3D8x10_pages%3A8x10lf */}
      <div className="App">
          <link rel="stylesheet" href="https://use.typekit.net/kok2vym.css"></link>
          <Navigation />
          <Home />
      </div>
    </>
  );
}

export default App;
