import React from 'react';
import Navigation from '../components/Navigation';
import { useParams } from "react-router-dom";

function SavedBook(){
    const { productCode, openBookURL, email } = useParams();
    
    return (
        <div className="App">
            <Navigation />
            <div className="identifyDiv">
                <label className="identifyEmailLabel">We Saved Your Book</label>
                <br></br><br></br>
                <span className="savedProjectSpan">You can access it anytime by logging in to <br></br><a href="https://shop.socialprintstudio.com/account">your account</a>. We’ve also sent an email.</span>
                {/* <h1>Your { productCode } has been save into your shopify account. </h1>
                <button type="submit" onClick={() => {window.location.href = "https://shop-staging.socialprintstudio.com/account"}} >Go to your Account</button> */}
                <div className="savedProjectButtonsDiv">
                    <button type="submit" onClick={() => {window.location.href = decodeURIComponent(openBookURL) }} >continue editing</button>
                    <button className="backToSiteButton" type="submit" onClick={() => {window.location.href = "https://socialprintstudio.com"}} >continue shopping</button>
                </div>
            </div>
        </div>
    )
}

export default SavedBook;