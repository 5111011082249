import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Navigation from '../components/Navigation';
import { useState } from 'react';
import Axios from 'axios';
import { UserContext } from '../userDetails';

function Login(){
    const [email, setEmail] = useState("your email");
    const [password, setPassword] = useState("password");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { setIsAuth } = useContext(UserContext);

    const submitForm = (event) => {
        event.preventDefault();
        console.log(email, password);
        Axios.post('/doLogin', {
            email: email,
            password: password
        }).then( (res) => {
            if(res.status === 200){
                setIsAuth(true);
                navigate("/admin");
            } else {
                setIsAuth(false);
                setError("Wrong pass.");    
            }
        }).catch((e) => {
            setIsAuth(false);
            setError("Wrong pass.");
        });
    };

    return (
        <div>
            <Navigation />
            <div className="container">
                <h3>Login</h3>
                <form onSubmit={submitForm}>
                    <div>
                        <label>Uour Email</label>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                    <div>
                        <label>Password</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    </div>
                    <button type="submit">Log in</button>
                </form>
                <label>{error}</label>
            </div>
        </div>
    )
}

export default Login;