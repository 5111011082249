import React from 'react';
import AdminForm from '../components/AdminForm';
import Navigation from '../components/Navigation';
//import { useParams } from "react-router-dom";

function Admin(){
    // const { next } = useParams();

    return (
        <div className="App">
            <Navigation />
            <div className="container">
             <AdminForm />
            </div>
        </div>
    )
}

export default Admin;