import { useContext } from 'react';

import { Navigate, Outlet } from 'react-router';
import { UserContext } from './userDetails';

const ProtectedRoute = (props) => {
 const { isAuth } = useContext(UserContext);
 return isAuth ? <Outlet /> : <Navigate to="/login" />   
}

export default ProtectedRoute;