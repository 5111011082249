import { Component } from 'react';
import Axios from 'axios';
import validator from 'validator';
import copy from 'copy-to-clipboard';

class AdminForm extends Component{

    constructor(props){
        super(props)
        this.state = {
            email: '',
            validEmail: true,
            savedProjects: {},
        }
    }
    validateEmail = (e) => {
        var email = e.target.value
        this.setState({
            email: e.target.value
        })
        
        if (validator.isEmail(email)) {
            this.setState({
                validEmail: true,
            })
        } else {
            this.setState({
                validEmail: false,
            })
        }
    }
    handlerEmailChange = event => {
        this.setState({
            email: event.target.value
        })
    }
    submitForm  = async( event) => {
        event.preventDefault();
        let email = this.state.email;
        await Axios.get('/getSavedProjects/' + email).then( (res) => {
            let data = res.data;
            if(data.savedProjects){
                this.setState({savedProjects: data.savedProjects});
            }
        });
    }
    deleteProject(projectToken) {
        Axios.post('/deleteProject', {
            token: projectToken
        }).then( (res) => {
            let data = res.data;
            if(data.savedProjects){
                this.setState({savedProjects: data.savedProjects});
            }
        });
    }

    render() {
        const projects = Object.keys(this.state.savedProjects).map((key) => (
            <tr key={key}>
                <td>{ this.state.savedProjects[key].title }</td>
                <td>{ this.state.savedProjects[key].date }</td>
                <td>
                    <a target="_blank" href={ this.state.savedProjects[key].url } rel="noreferrer"> open </a>
                    <a value={key} href="#" onClick={() => this.deleteProject(key)}> delete </a>
                    <a value={key} href="#" onClick={() => copy(this.state.savedProjects[key].url)}> copy </a>
                </td>
            </tr>
        ));
        return (
            <div>
                <div className="identifyDiv">
                    <form onSubmit={this.submitForm}>
                        <div>
                            <label>Email</label>
                            <input type="text" value={this.state.email} onChange={this.validateEmail}></input>
                        </div>
                        <label className="errorLabel" hidden={this.state.validEmail}>Please provide a valid email.</label>
                        <br></br>
                        <button type="submit" disabled={!this.state.validEmail}>Get customer projects</button>
                    </form>
                    <label>{this.state.msg}</label>
                </div>
                <div id="layout-content" className="layout-content-wrapper" hidden={Object.keys(this.state.savedProjects).length === 0} >
                    <div className="panel-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product code</th>
                                    <th>Saved Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                               {projects}
                            </tbody> 
                         </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminForm