import React from 'react';
import IdentifyForm from '../components/IdentifyForm';
import Navigation from '../components/Navigation';
import { useParams } from "react-router-dom";

function Identify(){
    const { next } = useParams();

    return (
        <div className="App">
            <Navigation />
            <div className="container">
                <IdentifyForm next={next}/>
            </div>
        </div>
    )
}

export default Identify;