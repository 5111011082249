import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//import Register from './Views/register';
import Login from './Views/login';
import Identify from './Views/identify';
import IdentifyBook from './Views/identifyBook';
import SavedBook from './Views/SavedBook';
import Admin from './Views/Admin';
import ProtectedRoute from './ProtectedRoute';
import { UserProvider } from './userDetails';

// ReactDOM.render(
//   <BrowserRouter> 
//     <App />  
//   </BrowserRouter>,
//   document.getElementById('root')
// );

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="Login" element={<Login />} />
        {/* <Route path="Register" element={<Register />} /> */}
        <Route path="identify/:next" element={<Identify /> } />
        <Route path="identifyBook/:next" element={<IdentifyBook /> } />
        <Route path="SavedBook/:productCode/:openBookURL/:email" element={<SavedBook /> } />
        <Route element={<ProtectedRoute />}>
          <Route path="Admin/DASxasRASTySTHanSnauiltRh" element={<Admin />} />
          <Route path="Admin" element={<Admin />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </UserProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
