import { useState, createContext, useMemo } from 'react';

const UserContext = createContext(); 

const UserProvider = (props) => {
    const [isAuth, setIsAuth] = useState(false);

    const value = useMemo(   
        () => ({isAuth, setIsAuth}),[isAuth]
    );

    return (
        <UserContext.Provider
            value={value}
        >
            {props.children}
        </UserContext.Provider>
    );
}
export { UserContext, UserProvider };