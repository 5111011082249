import { Component } from 'react';
import Axios from 'axios';
import { Link } from "react-router-dom";

class Home extends Component{
    constructor(props){
        super(props)

        this.state = {
            savedProjects: {},
            msg: ''
        }
    }
    componentDidMount() {
        //this.getProjectList();
        window.location.href = 'https://socialprintstudio.com';
    }

    getProjectList = async( event) => {
        //getProjectList () => {
        event.preventDefault();
        Axios.get('/getSavedProjects').then( (res) => {
            let data = res.data;
            console.log(typeof data.savedProjects);
            if(data.savedProjects){
                this.setState({savedProjects: data.savedProjects});
            }
        });
    }

    destroySession = async( event) => {
        //getProjectList () => {
        event.preventDefault();
        Axios.get('/destroy').then( (res) => {
            let data = res.data;
            if(data){
                this.setState({
                    msg: data,
                });
            }
        });
    }

    submitFormGetCartID  = async( event) => {
        event.preventDefault();
        await Axios.get('/cart-id').then( (res) => {
            let data = res.data;
            console.log(data);
            if(data){
                this.setState({
                    msg: data,
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    submitFormGetEmail = async( event) => {
        event.preventDefault();
        await Axios.get('/getSessionData').then( (res) => {
            let data = res.data;
            console.log(data);
            if(data){
                this.setState({
                    msg: data.email,
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    deleteProject(projectToken) {
        Axios.post('/deleteProject', {
            token: projectToken
        }).then( (res) => {
            console.log(res.data);
        });
    }

    render() {
        const projects = Object.keys(this.state.savedProjects).map((key) => (
            <div key={key}>
                <label>{ this.state.savedProjects[key].title }</label>
                <a target="_blank" href={ this.state.savedProjects[key].url } rel="noreferrer"> link </a>
                <label>{ this.state.savedProjects[key].date }</label>
                <button value={key} onClick={() => this.deleteProject(key)}> delete </button>
            </div>
        ));
        return (
            <div>
                <h3>Home Page</h3>
                <div>
                    <nav>
                        <Link to="/login">Admin</Link> 
                        <Link to="/admin">Admin</Link> 
                    </nav>
                    <form onSubmit={this.submitFormGetCartID}>
                        <button type="submit">Get cartID</button>
                    </form>
                    <label>{this.state.msg}</label>
                    <form onSubmit={this.submitFormGetEmail}>
                        <button type="submit">Who am I?</button>
                    </form>

                    <form onSubmit={this.getProjectList}>
                        <button type="submit">Get Saved Projects</button>
                    </form>
                    <form onSubmit={this.destroySession}>
                        <button type="submit">Destroy my session</button>
                    </form>
                </div>
                <div id="layout-content" className="layout-content-wrapper">
                    <div className="panel-list">
                         {projects} 
                    </div>
                </div>
            </div>
        )
    }
}

export default Home