import { Component } from 'react';
//import { Link } from "react-router-dom";

class Navigation extends Component{
    render() {
        return (
            <div >
                <div className="navbar">
                    {/* <div className="flex-row">
                        <label className="title">FREE US SHIPPING $60+, ALWAYS</label>
                    </div> */}
                    <div className="navbar-heart">

                    {/* </div>
                    <div id="menu" className="flex-row end">
                    <nav>
                        <Link to="/">Home</Link> |{" "}
                        <Link to="/login">Login</Link> |{" "}
                        <Link to="/register">Register</Link> |{" "}
                        <Link to="/identify">Identify</Link> |{" "}
                        <Link to="/admin">Admin</Link> 
                    </nav>             */}
                    </div>
                </div>                
            </div>
            
        )
    }
}

export default Navigation