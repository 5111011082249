import { Component } from 'react';
import Axios from 'axios';
import validator from 'validator'
class IdentifyFormBook extends Component{
    constructor(props){
        super(props)
        this.state = {
            email: '',
            msg: '',
            validEmail: false,
            acceptMarketingMaterial: true,
        }
    }
    validateEmail = (e) => {
        var email = e.target.value
        this.setState({
            email: e.target.value
        })
        
        if (validator.isEmail(email)) {
            this.setState({
                validEmail: true,
            })
        } else {
            this.setState({
                validEmail: false,
            })
        }
    }
    handlerEmailChange = event => {
        this.setState({
            email: event.target.value
        })
    }
    submitForm = async (event) => {
        event.preventDefault();
        console.log(this.state.email);
        await Axios.post('/identifyBook', {
            email: this.state.email,
            acceptMarketingMaterial: this.state.acceptMarketingMaterial,
            bookURL: this.props.next
        }).then( (res) => {
            let data = res.data;
            if (data.redirect){
                window.location.href = decodeURIComponent("https://www.socialprintstudio.com/layflat-success/");
            }
            if(data.msg){
                this.setState({
                    msg: data.msg,
                });
            }
        });
    }

    render() {
        return (
            <div className="identifyBookDiv">
                <form onSubmit={this.submitForm}>
                    <div>
                        <label className="identifyEmailLabel">Excellent choices!</label>
                        <br></br><br></br>
                    </div>
                    <div>
                        <span className="identifyBody">Enter your email, and we'll send you a link to access your Wedding Guest Book project with your selections so you can get started on a computer. We'll also create an account for you or save your order to an existing account if you already have one. </span>
                        <br></br><br></br>
                    </div>
                    <br></br>
                    <div className="indentifyInputDiv">
                        <input className="identifyInput" type="text" placeholder="Email Address" value={this.state.email} onChange={this.validateEmail}></input>
                        <button className="identifyButton" type="submit" disabled={!this.state.validEmail}>Continue</button>
                    </div>
                    {/* <div className="identifyCheckbox">
                        <input className="identifyCheckboxInput" type="checkbox" defaultChecked={this.state.acceptMarketingMaterial} onChange={(e) => this.setState({acceptMarketingMaterial: e.target.value })} ></input>
                        <label className="identifyCheckboxLabel">Sign up for emails + take 15% off your first order.</label>
                    </div> */}
                    {/* <label className="errorLabel" hidden={this.state.validEmail}>Please provide a valid email.</label> */}
                    
                </form>
                <label>{this.state.msg}</label>
            </div>
        )
    }
}

export default IdentifyFormBook